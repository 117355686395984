/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from "@emotion/react";
import Img from '../components/Img';
import ButtonTheme from '../components/common/Button';
import TestimonialsSlider from '../components/Testimonials'
import PricingSection from '../components/Pricing';
import Loader from '../components/common/Loader';

interface SubHeadingProps {
  marginBottom?: string; // Allow dynamic margin-bottom as a prop
}
const HomeContainer = styled.div`
`;

// Emotion CSS styles
const heroBannerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 91px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const textContainer = css`
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const headingStyle = css`
  font-size: 3.2rem;
  margin-bottom: 20px;
  margin-top: 0;
`;

const paragraphStyle = css`
  font-size: 1rem;
  margin-bottom: 30px;
`;

const imageContainer = css`
  max-width: 50%;
  width: 100%;

  img {
    width: 80%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

// Emotion CSS styles Section 2
const whyBGhostSectionStyle = css`
  text-align: center;
  padding: 60px 20px;
`;

const iconGrid = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const iconBox = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

const iconStyle = css`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`;

const iconHeadingStyle = css`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333333;
`;

const iconTextStyle = css`
  font-size: 1rem;
  color: #555555;
`;


// Emotion CSS styles Section 3
const howItWorksSectionStyle = css`
text-align: center;
padding: 30px 20px;
`;

// Emotion CSS styles with dynamic margin
const subHeadingStyle = (props: SubHeadingProps) => css`
  font-size: 1.8rem;
  margin-bottom: ${props.marginBottom || '10px'}; // Default to 20px if no margin provided
`;

const stepContainer = css`
display: flex;
flex-direction: column;
gap: 40px;
`;

const stepStyle = css`
display: flex;
align-items: center;
gap: 20px;
@media (max-width: 768px) {
  flex-direction: column;
  text-align: center;
}
`;

const stepReverseStyle = css`
flex-direction: row-reverse;
@media (max-width: 768px) {
  flex-direction: column;
}
`;

const imageStyle = css`
width: 100%;
height: auto;
max-width: 500px;
`;

const stepTextContainer = css`
flex: 1;
text-align: left;
@media (max-width: 768px) {
  text-align: center;
}
`;

const stepNumberStyle = css`
font-size: 2.3rem;
margin-bottom: 5px;
`;

const stepTextStyle = css`
font-size: 1.4rem;
color: #555555;
`;

// Emotion CSS styles
const footerContainer = css`
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #111;
  border-top: 1px solid #ddd;
`;

const footerContent = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const footerLogoSection = css`
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;


const socialIcons = css`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  font-size: 0.7rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const socialIcon = css`
  width: 30px;
  height: 30px;
  filter: grayscale(100%);
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(0%);
  }
`;

const footerLinks = css`
  text-align: left;

  h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 8px;
  }

  a {
    color: #111;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const footerContact = css`
  text-align: left;

  h4 {
    font-size: 1.2rem;
    margin-top: 0px;
  }

  a {
    color: #111;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const copyrightText = css`
  font-size: 0.9rem;
  color: #777;
`;

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <HomeContainer>
      {loading ? <Loader /> : 
      <div css={heroBannerStyle}>
        <div css={textContainer}>
          <h1 css={headingStyle}>
            Elevate Your Videos <br /> with AI Face & Voice Technology
          </h1>
          <p css={paragraphStyle}>
            Experience the future of digital content with our AI-powered platform,
            where you can seamlessly transform faces and voices in videos.
          </p>
          <ButtonTheme primary borderRad='30px' background={'#0C0C0C'} color='#fff' type="submit" > Get Started For Free </ButtonTheme>
        </div>
        <div css={imageContainer}>
          <Img ImgName='img/Main_Image.png' alt='Main Image' />
        </div>
      </div>
    }
      {/* WHY B-Ghost Section */}
      <section css={whyBGhostSectionStyle}>
      <h2 css={headingStyle}>Why B-Ghost?</h2>
      <div css={iconGrid}>
        <div css={iconBox}>
          <Img ImgName='img/swap-1.png' alt='Icon 1' css={iconStyle} />
          <h3 css={iconHeadingStyle}>Face Swapping</h3>
          <p css={iconTextStyle}> Seamlessly swap faces in your videos with cutting-edge AI technology.</p>
        </div>
        <div css={iconBox}>
          <Img ImgName='img/mic-1.png' alt='Icon 2' css={iconStyle} />
          <h3 css={iconHeadingStyle}>Voice Changer</h3>
          <p css={iconTextStyle}> Transform voices effortlessly with advanced AI voice modulation.</p>
        </div>
        <div css={iconBox}>
          <Img ImgName='img/player-1.png' alt='Icon 2' css={iconStyle} />
          <h3 css={iconHeadingStyle}>High Quality</h3>
          <p css={iconTextStyle}> Ensure the highest quality for your video content, powered by AI.</p>
        </div>
      </div>
    </section>

    {/* SECTION 3 HOW ITS WORK */}
    <section css={howItWorksSectionStyle}>
      <h2 css={headingStyle}>How It Works?</h2>
      <p css={subHeadingStyle({ marginBottom: '40px' })}>Simple, Fast, and Fun!</p>
      
      <div css={stepContainer}>
        {/* Step 1 */}
        <div css={stepStyle}>
          <div css={imageContainer}>
            <Img ImgName='img/upload-a-video.png' alt='Select Face and Voice' css={imageStyle} />
          </div>
          <div css={stepTextContainer}>
            <h3 css={stepNumberStyle}>1.</h3>
            <h3 css={stepNumberStyle}>Upload Your Video</h3>
            <p css={stepTextStyle}>Choose a video in the app.</p>
          </div>
        </div>

        {/* Step 2 */}
        <div css={[stepStyle, stepReverseStyle]}>
         <div css={imageContainer}>
            <Img ImgName='img/uploadFace.png' alt='Select Face and Voice' css={imageStyle} />
          </div>
          <div css={stepTextContainer}>
            <h3 css={stepNumberStyle}>2. </h3>
            <h3 css={stepNumberStyle}>Select Face </h3>
            <p css={stepTextStyle}>
              Pick your new face from our library.
            </p>
          </div>
        </div>

        {/* Step 3 */}
        <div css={stepStyle}>
          <div css={imageContainer}>
          <Img ImgName='img/SelectVoice.png' alt='Select Face and Voice' css={imageStyle} />
          </div>
          <div css={stepTextContainer}>
            <h3 css={stepNumberStyle}>3. </h3>
            <h3 css={stepNumberStyle}>Pick A Voice</h3>
              <p css={stepTextStyle}>Pick your new Voice from our library.</p>
            </div>
          </div>

          {/* Step 4 */}
          <div css={[stepStyle, stepReverseStyle]}>
            <div css={imageContainer}>
              <Img ImgName='img/share.png' alt='Select Face and Voice' css={imageStyle} />
            </div>
            <div css={stepTextContainer}>
              <h3 css={stepNumberStyle}>4. </h3>
              <h3 css={stepNumberStyle}>Share with the World</h3>
              <p css={stepTextStyle}>
                Apply the changes and share your video with the world
              </p>
            </div>
          </div>
      </div>
    </section>

    {/* SECTION 4 TESTIMONIALS */}
    <section css={whyBGhostSectionStyle}>
      <p css={subHeadingStyle({})}>Testimonials</p>
      <h2 css={headingStyle}>What Our Users Say</h2>
      <TestimonialsSlider />
    </section>

    {/* SECTION 5 Pricing */}
    <PricingSection />
    
    
    {/* FOOTER  */}

    <footer css={footerContainer}>
      <div css={footerContent}>
        {/* Logo and Social Icons */}
        <div css={footerLogoSection}>
          <Img ImgName='logos/logo_black.png' alt='Logo' width={250} />
          <div css={socialIcons}>
            <a href="#" aria-label="LinkedIn">
              <img
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                alt="LinkedIn"
                css={socialIcon}
              />
            </a>
            <a href="#" aria-label="X (formerly Twitter)">
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
                alt="X"
                css={socialIcon}
              />
            </a>
            <a href="#" aria-label="Facebook">
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                alt="Facebook"
                css={socialIcon}
              />
            </a>
            <a href="#" aria-label="Instagram">
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733558.png"
                alt="Instagram"
                css={socialIcon}
              />
            </a>
          </div>
        </div>

        {/* Company Links */}
        <div css={footerLinks}>
          <h4>Company</h4>
          <ul>
            <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Blog</a>
            </li>
            <li>
              <a href="#">Research</a>
            </li>
            <li>
              <a href="#">News</a>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div css={footerContact}>
          <h4>Contact Us</h4>
          <p>
            <a href="mailto:info@bghost.com">info@bghost.com</a>
          </p>
        </div>
      </div>
      <p css={copyrightText}>Copyright © B-Ghost. All rights reserved</p>
    </footer>

    </HomeContainer>
  );
};

export default Home;