import React from 'react';
import styled from '@emotion/styled';

const AboutContainer = styled.div`
  padding: 2rem;
`;

const About: React.FC = () => {
  return (
    <AboutContainer>
      <h1>About BGhost</h1>
      <p>BGhost is an innovative app designed to revolutionize your digital experience.</p>
    </AboutContainer>
  );
};

export default About;