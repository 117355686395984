import styled from '@emotion/styled';
import { FaFileVideo } from 'react-icons/fa'; // Importing an icon from react-icons
import { DetectedFaceProps } from '../types/interfaces';

export const PageContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem; /* Add some space between items */
    
    @media (min-width: 768px) {
        flex-direction: row; /* Change to row on larger screens */
        align-items: flex-start;
        justify-content: space-between;
    }
`;

export const DropzoneContainer = styled.div`
    border: 2px dashed ${props => props.theme.colors.background};
    border-radius: 4px;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    flex: 1; /* Make it take up available space */
    
    &:hover {
        background-color: ${props => props.theme.colors.bgLightWhite};
    }
`;

export const UploadedFileInfo = styled.div`
    border-radius: 4px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
        color: #fff;
    }
`;

export const VideoPreviewContainer = styled.div`
    margin-top: 2rem;
    flex: 1;
`;

export const VideoPlayer = styled.video`
    width: 100%;
    height: 400px; /* Set video height */
    max-width: 600px;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
`;


export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 0rem;
`;

export const Block = styled.div`
    width: 50%;
    padding: 1rem;
    box-sizing: border-box; /* Ensure padding doesn't affect the width */
`;

export const LeftBlock = styled(Block)`
    border-radius: 10px;
    background: ${props => props.theme.colors.background};
    transition: opacity 0.5s ease-in-out;
`;

export const RightBlock = styled(Block)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: opacity 0.5s ease-in-out;
`;

export const FileInfo = styled.div`
    display: flex;
    align-items: center;
    color: white;
`;

export const FileIcon = styled(FaFileVideo)`
    margin-right: 1rem;
    font-size: 2rem;
`;

export const FileName = styled.span`
    font-size: 1rem;
`;

export const ChangeButton = styled.button`
    padding: 0.5rem 1rem;
    background: none;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;

export const SubHeading = styled.h3`
    margin: 0 0 1rem;
    color: ${props => props.theme.colors.textDark};
    background: ${props => props.theme.colors.textDark};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
`;

export const NextButton = styled.button`
    width: 100%;
    font-size: 1.5rem;
    margin-top: 10px;
    padding: 0.75rem 1.5rem;
    background-color: ${props => props.theme.colors.background};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.lightSecondary};
    }
`;

export const ChooseImageButton = styled.button`
    margin-top: auto;
    margin-bottom: 10px;
    padding: 0.75rem 1.5rem;
    border: 2px dashed ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.textDark};
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    font-size: 1.2rem;
    background: transparent;   
`;

export const SubHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const SubHeadingBlock = styled.div`
    width: 45%;
    padding: 0.5rem;
    box-sizing: border-box;
`;

export const SubHeadingBlockVoice = styled.div`
    padding: 0.5rem;
    box-sizing: border-box;
`;


export const DetectedContainer = styled.div`
    padding: 1rem;
    border-radius: 10px;
    background: ${props => props.theme.colors.background};
`;

export const DetectedFace = styled.div<DetectedFaceProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem;
  cursor: pointer;
    border: ${(props) => (props.isActive ? `1px dashed ${props.theme.colors.bgLightWhite}` : 'none')};
  border-radius: 5px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Square = styled.div`
    width: 60px;
    height: 60px;
    background-color: #ccc;
    border-radius: 10px;
`;

export const CircleDetectedFace = styled.div`
    width: 60px;
    height: 60px;
    background-color: #ccc;
    border-radius: 50%;
    margin-bottom: 5px;
`;

export const Circle = styled.div`
    width: 60px;
    height: 60px;
    background-color: #ccc;
    border-radius: 50%;
    margin-bottom: 5px;

    & img {
        width: inherit;
        border-radius: 100px;
        height: inherit;
        object-fit: contain;
    }
`;

export const Arrow = styled.div`
    font-size: 1.5rem;
    color: white;
`;

export const UploadAudioButton = styled.button`
    color: ${props => props.theme.colors.textDark};  /* Text color */
    border: none;  /* Remove default border */
    border-radius: 4px;  /* Rounded corners */
    padding: 10px 20px;  /* Padding for the button */
    font-size: 16px;  /* Font size */
    font-weight: bold;  /* Bold text */
    cursor: pointer;  /* Pointer cursor on hover */
    transition: background-color 0.3s ease;  /* Smooth background color transition */
    border-radius: 5px;
    border: 1px #838383 dashed;
    background: transparent;
    text-align: center;
    margin: 0 auto;

    &:hover {
        background-color: ${props => props.theme.colors.bgLightWhite};  /* Change background color on hover */
    }


    &:focus {
        outline: none;  /* Remove default focus outline */
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);  /* Custom focus outline */
    }
`;


export const VoiceContainer = styled.div`
    padding: 20px;
    border: 2px dashed ${props => props.theme.colors.background};
    border-radius: 10px;
    width: 99%;
    margin: 20px auto;
    height: 200px;
    overflow-y: scroll;

    /* Custom Scrollbar */
    ::-webkit-scrollbar {
        width: 5px; /* Width of the scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: transparent; /* Transparent background for the track */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #B0B0B0; /* Color of the scrollbar thumb */
        border-radius: 10px;
    }
`;

export const VoiceRow = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
`;

export const PlayIcon = styled.div`
    width: 30px;
    height: 30px;
    background-color: ${props => props.theme.colors.background};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.bgLightWhite};
    font-size: 14px;
    margin-right: 15px;
`;

export const VoiceName = styled.div`
    flex: 1;
    color: ${props => props.theme.colors.textDark};
    font-size: 16px;
`;

export const TagWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const VoiceTag = styled.div`
    background-color: transparent;
    color: ${props => props.theme.colors.lightSecondary};
    padding: 5px 10px;
    border-radius: 12px;
    border: 1px solid #2a2525;
    font-size: 10px;
    margin-right: 8px;
`;

export const SelectVoiceButton = styled.button`
    border: none;
    padding: 5px 15px;
    border-radius: 12px;
    cursor: pointer;
`;