import styled from "@emotion/styled";
import React from "react";

const InputFeildContainer = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #e2e2e2;
    height: 40px;
    padding: 0 10px;
  }
`;

interface InputFeildProps {
    label: string;
    type: string;
    value: string;
    setValue: (value: string) => void;
}

const InputFeild: React.FC<InputFeildProps> = ({ label, type, value, setValue }) => (
    <InputFeildContainer>
        <span>{label}</span>
        <input
            type={type}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    </InputFeildContainer>
);

export default InputFeild;
