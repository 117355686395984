import React, { useRef } from 'react';
import styled from '@emotion/styled';

const VideoItemContainer = styled.div` border-radius: 8px; min-width: 270px; flex: 0 0 auto; `;
const Thumbnail = styled.div`
  background-color: #e2e2e2;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  position: relative;
  &:hover { opacity: 0.9; }
`;
const PlayButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  position: absolute;
  cursor: pointer;
`;
const VideoTitle = styled.p` font-size: 0.9rem; margin:0; margin-top: 5px; font-weight: 500; `;
const VideoDate = styled.p` font-size: 0.8rem; color: #7d7d7d; margin-top: 0; `;

interface VideoItemProps {
    title: string;
    date: string;
}

const VideoItem: React.FC<VideoItemProps> = ({ title, date }) => (
    <VideoItemContainer>
        <Thumbnail><PlayButton>▶</PlayButton></Thumbnail>
        <VideoTitle>{title}</VideoTitle>
        <VideoDate>{date}</VideoDate>
    </VideoItemContainer>
);

const VideoGrid = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;
  cursor: grab;
  &::-webkit-scrollbar { display: none; }
  scrollbar-width: none;
`;

const videos = [
    { id: 1, title: 'Final_20240801.mp4', date: '2024-04-06' },
    { id: 2, title: 'Final_20240801.mp4', date: '2024-04-06' },
    { id: 3, title: 'Final_20240801.mp4', date: '2024-04-06' },
    { id: 4, title: 'Final_20240801.mp4', date: '2024-04-06' },
    { id: 5, title: 'Final_20240801.mp4', date: '2024-04-06' },
];

const VideoList: React.FC = () => {
    const videoGridRef = useRef<HTMLDivElement>(null);

    const handleMouseEvents = (e: React.MouseEvent, isDown: boolean) => {
        if (!videoGridRef.current) return;
        videoGridRef.current.style.cursor = isDown ? 'grabbing' : 'grab';
        if (isDown) {
            const startX = e.pageX - videoGridRef.current.offsetLeft;
            const scrollLeft = videoGridRef.current.scrollLeft;
            videoGridRef.current.onmousemove = (moveEvent) => {
                const x = moveEvent.pageX - videoGridRef.current!.offsetLeft;
                videoGridRef.current!.scrollLeft = scrollLeft - (x - startX) * 2;
            };
        } else {
            videoGridRef.current.onmousemove = null;
        }
    };

    return (
        <VideoGrid
            ref={videoGridRef}
            onMouseDown={(e) => handleMouseEvents(e, true)}
            onMouseUp={(e) => handleMouseEvents(e, false)}
            onMouseLeave={(e) => handleMouseEvents(e, false)}
        >
            {videos.map((video) => (
                <VideoItem key={video.id} title={video.title} date={video.date} />
            ))}
        </VideoGrid>
    );
};

export default VideoList;
