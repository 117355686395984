import React from 'react';
import {  Route, Routes } from 'react-router-dom';
import MainLayout from '../components/Layout/MainLayout';
import Home from '../pages/Home';
import About from '../pages/About';
import VideoUploadPage from '../pages/UploadVideo';
import Result from '../pages/result';
import ProfilePage from '../pages/ProfilePage';
import Dashboard from '../pages/Dashboard';
import ProtectedUploadRoute from '../pages/ProtectedUploadRoute';

const AppRoutes: React.FC = () => {
  return (
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          
          {/* New routes for the multi-step video editing process */}
          <Route path="/upload" element={<ProtectedUploadRoute />} />
          <Route path="/result" element={<Result />} />

          <Route path="/setting/profile" element={<ProfilePage />} />

          
        <Route path='/dashboard' element={<Dashboard />} />
        </Route>
      </Routes>
  );
};

export default AppRoutes;
