import React from 'react';
import styled from '@emotion/styled';
import VideoList from '../components/dashboard/VideoList';
import PaymentTable from '../components/dashboard/PaymentTable';

const Container = styled.div` padding-top: 0.5rem; `;
const Title = styled.h2` font-size: 1.5rem; margin-bottom: 1rem; `;

const Dashboard: React.FC = () => {
  return (
    <Container>
      <h1>Dashboard</h1>
      <Title>Recent work</Title>
      <VideoList />
      <Title>Payment History</Title>
      <PaymentTable />
    </Container>
  );
};

export default Dashboard;
