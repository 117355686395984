export const theme = {
  colors: {
    primary: '#459AC9',
    lightSecondary: '#333',
    background: '#0C0C0C',
    backgroundLight: '#f9f9f9',
    textLight: '#ffffff',
    textDark: '#000000',
    hoverDarkButton: '#1a1a1a',
    hoverLightButton: '#e5e5e5',
    bgLightWhite: '#F3F3F3',
  },
  fontSizes: {
    small: '0.8rem',
    medium: '1rem',
    large: '1.2rem',
  },
};

export type Theme = typeof theme;