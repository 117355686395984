// src/components/Logo.tsx
import React from 'react';
import { getLogoUrl } from '../utils/logoSolver';

interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  ImgName: string;
  alt?: string;
}

const Img: React.FC<LogoProps> = ({ ImgName, alt = 'Img', ...props }) => {
   const logoUrl = getLogoUrl(ImgName);

  console.log(`Logo URL: ${logoUrl}`); // Debugging: Check if URL is correct

  return <img src={logoUrl} alt={alt} {...props} />;
};

export default Img;
