import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../styles/theme';

interface ButtonProps {
  primary?: boolean;
  width?: string; // Add width as a prop
}

interface ButtonProps {
  primary?: boolean;
  width?: string;
  padding?: string;
  border?: string;
  background?: string;
  borderRad?: string;
}

const StyledButton = styled.button<ButtonProps>`
  background-color: ${(props) => props.background || 'Transparent'};
  color: ${(props) => (props.primary ? props.theme.colors.backgroundLight : props.theme.colors.background)};
  border: ${(props) => props.border || `1px solid ${props.theme.colors.backgroundLight}`};
  padding: ${(props) => props.padding || '0.5rem 1rem'};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.medium};
  width: ${(props) => props.width || 'auto'};
  font-family: inherit;
  border-radius: ${(props) => props.borderRad || '5px'};

  &:hover {
    opacity: 0.5;
  }
`;

const ButtonTheme: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = ({ children, width, padding, border, background, ...props }) => {
  return <StyledButton width={width} padding={padding} border={border} background={background} {...props}>{children}</StyledButton>;
};

export default ButtonTheme;