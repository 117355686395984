// src/components/SignInForm.tsx
import React, { useState } from 'react';
import styled from '@emotion/styled';
import ButtonTheme from './Button';
import qs from 'qs'; // Make sure to install 'qs' package
import Swal from 'sweetalert2';
import axios from 'axios'

interface StyledProps {
  $margin?: string;
  $textAlign?: string;
}

const Heading = styled.h2`
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 109.375% */
  background: radial-gradient(81.15% 39.38% at 51.91% 38.33%, #FFF 0.34%, #B0B0B0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  text-align: left; /* Optional: Center the text */
  line-height: 1;
`;


const HeadingForgot = styled.h3`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 109.375% */
  background: radial-gradient(81.15% 39.38% at 51.91% 38.33%, #FFF 0.34%, #B0B0B0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  text-align: left; /* Optional: Center the text */
  line-height: 1;
`;

const Para = styled.p`
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  margin: 8px 0 15px 0;
`;

// Label
const Label = styled.label`
  color: ${props => props.theme.colors.backgroundLight}; 
  font-family: inherit; /* Fallback fonts */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 8px;
`;

// Input
const Input = styled.input`
  border-radius: 5px;
  background: ${props => props.theme.colors.bgLightWhite}; ;
  border: 1px solid #B0B0B0; /* Add border color to match the label */
  font-family: inherit; /* Fallback fonts */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  width: 100%; /* Full width for responsive design */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border and shadow */
  
  &:focus {
    outline: none;
    border-color: #1E90FF; /* Change border color on focus */
    box-shadow: 0 0 8px rgba(30, 144, 255, 0.5); /* Add a glow effect */
  }
`;

// Form Field
const FormField = styled.div<StyledProps>`
    margin: ${props => props.$margin || '0 0 0 0'};
`;

// Styled toggle text
const ToggleText = styled.div<StyledProps>`
  cursor: pointer;
  color: #fff;
  margin: ${props => props.$margin || '10px 0 0 0'};
  text-align: ${props => props.$textAlign || 'center'};
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }

  span {
    font-weight: bold;
  }
`;

const SignInForm: React.FC = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [formDatalogin, setformDatalogin] = useState({
    email: '',
    password: ''
  });
  const handleToggle = () => {
    setIsSignUp(!isSignUp);
    setIsForgotPassword(false); // Reset forgot password state when toggling
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleBackToSignIn = () => {
    setIsForgotPassword(false);
  };
  const [formData, setFormData] = useState({
    First_Name: '',
    Last_Name: '',
    Email: '',
    user_Name:'',
    Password: ''
    //Password
  });
  const handleInputLoginChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
    const { name, value } = e.target;
    setformDatalogin((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      user_Name: `${prevData.First_Name} ${prevData.Last_Name}`
    }));

     };
     const handleLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault(); // Prevent page refresh on form submission
  
      try {
        const response = await axios.post('https://api.bghost.ai/User/api/auth/login', qs.stringify(formDatalogin), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
  
        const responseCode = response.data.code; // Assuming this is how the API indicates success
        if (responseCode === 200) {
          const userData = response.data.data[0]; // Adjust based on your API response structure
  
          // Save token and user information in localStorage
          localStorage.setItem('token', userData.token);
          localStorage.setItem('username', userData.user_Name);
          localStorage.setItem('email', userData.email);
  
          Swal.fire({
            title: 'Success!',
            text: 'Login successful!',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            // Redirect to a specific page after confirmation
            window.location.href = '/'; // Replace with your actual redirect URL
          });
        } else {
          // Handle error response
          Swal.fire({
            title: 'Error!',
            text: response.data.message || 'Login failed. Please check your credentials.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error during login:', error);
        Swal.fire({
          title: 'Error!',
          text: 'Network error. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    };
     const handleSignUpSubmit = async (e: React.FormEvent) => {
   
      e.preventDefault();
      try {
  
        const response = await axios.post('https://api.bghost.ai/User/api/auth/signup', qs.stringify(formData), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        
        const responseapi = response.data.code;
        debugger  
        if(responseapi ==200)
        {
          const userData = response.data.data[0];
       
        // Save required fields in localStorage
        localStorage.setItem('token', userData.token);
        localStorage.setItem('username', userData.User_Name);
        localStorage.setItem('email', userData.Email);
        localStorage.setItem('phoneNumber', userData.Phone_Number);
        
      Swal.fire({
        title: 'Success!',
        text: 'Sign up successful!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        // Redirect to the desired page after SweetAlert confirmation
     
      });
      setIsSignUp(false);
        } else{
          Swal.fire({
            title: 'Error!',
            text: 'Something went wrong.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      
        setError(null); // Clear any previous errors
      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to create account');
      }
    };
  if (isForgotPassword) {
    return (
      <form>
        <HeadingForgot>Reset Password</HeadingForgot>
        <Para>Enter your email address to reset your password.</Para>
        <FormField $margin='0 0 20px 0'>
          <Label htmlFor="email">Email:</Label>
          <Input type="email" id="email" name="email" required />
        </FormField>

        <ButtonTheme 
          primary 
          type="submit" 
          width="100%" 
          background='Transparent' 
          border='1px solid'
        >
          Send Reset Link
        </ButtonTheme>
        
        <ToggleText 
          onClick={handleBackToSignIn} 
          $textAlign='center' 
          $margin='10px 0'
        >
          Back to <span>Sign In</span>
        </ToggleText>
      </form>
    );
  }

  return (
    <div>
      {isSignUp ? (
        <form onSubmit={handleSignUpSubmit}>
     
        <Heading>Hi there!</Heading>
        <Para>Create your account below</Para>
        <FormField>
          <Label htmlFor="firstName">First Name:</Label>
          <Input type="text"   onChange={handleChange} id="firstName" name="First_Name" required />
        </FormField>
        <FormField>
          <Label htmlFor="lastName">Last Name:</Label>
          <Input type="text"   onChange={handleChange} id="lastName" name="Last_Name" required />
        </FormField>
        <FormField>
          <Label htmlFor="email">Email:</Label>
          <Input type="email"   onChange={handleChange} id="email" name="Email" required />
        </FormField>
        <FormField>
          <Label htmlFor="password">Password:</Label>
          <Input type="password"   onChange={handleChange} id="password" name="Password" required />
        </FormField>
        <br /> {/* Adding a line break */}
        <ButtonTheme primary type="submit" width="100%" >Sign Up</ButtonTheme>
        <ToggleText onClick={handleToggle}>
          Already a member? <span>Sign in</span>
        </ToggleText>
      </form>
      ) : (
        <form>
          <Heading>Hi there!</Heading>
          <Para>Welcome to B-Ghost, so happy to see you!</Para>
          <FormField $margin='0 0 20px 0'>
            <Label htmlFor="email">Email:</Label>
            <Input type="email" id="email" onChange={handleInputLoginChange}  name="email" required />
          </FormField>
          <FormField >
            <Label htmlFor="password">Password:</Label>
            <Input type="password" id="password"  onChange={handleInputLoginChange} name="password" required />
          </FormField>
          
          <ToggleText 
            onClick={handleForgotPassword} 
            $textAlign='left' 
            $margin='1px 0 20px 0'
          >
            Forgot Password?
          </ToggleText>

          <ButtonTheme primary  type="submit" width="100%" background='Transparent' border='1px solid' 
          onClick={(event) => handleLogin(event)} 
           >Sign In</ButtonTheme>
          <ToggleText  onClick={handleToggle} >
            Not a member? <span>Sign Up</span>
          </ToggleText>
        </form>
      )}
    </div>
  );
};

export default SignInForm;
