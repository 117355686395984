import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the change here
import { ThemeProvider } from '@emotion/react';
import App from './App';
import { theme } from './styles/theme';

// Get the root element
const rootElement = document.getElementById('root');

if (rootElement) {
  // Create a root
  const root = ReactDOM.createRoot(rootElement);

  // Render the app
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
} else {
  console.error('Root element not found');
}