import { useState } from "react";
import styled from "@emotion/styled";
import { FaPencilAlt } from "react-icons/fa";
import ButtonTheme from "../common/Button";
import InputFeild from "../common/InputFeild";
import Img from "../Img";

const Container = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const FeildContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 50%;
`;

const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 0;
  }
  .image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: #e2e2e2;
    position: relative;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .edit-button {
    position: absolute;
    bottom: 2px;
    left: 5px;
    font-size: 12px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #ccc;
    padding: 0.15rem 0.5rem;
    svg {
    font-size: 10px;
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ProfileForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profile, setProfile] = useState('');

  const handleUpdateProfile = () => {
    console.log('Profile updated:', { email, firstName, lastName });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setProfile(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  return (
    <form>
      <Container>
        <FeildContainer>
          <InputFeild label="Email Address" type="email" value={email} setValue={setEmail} />
          <InputFeild label="First Name" type="text" value={firstName} setValue={setFirstName} />
          <InputFeild label="Last Name" type="text" value={lastName} setValue={setLastName} />
        </FeildContainer>

        <ImageContainer>
          <p>Profile Picture</p>
          <label htmlFor="image">
            <div className="image">
              {profile && <Img ImgName={profile} alt="Profile" className="img" />}
              <ButtonTheme
                type="button"
                onClick={() => document.getElementById('image')?.click()}
                className="edit-button"
              >
                <FaPencilAlt /> Edit
              </ButtonTheme>
            </div>
            <input type="file" id="image" onChange={handleImageChange} />
          </label>
        </ImageContainer>
      </Container>

      <ButtonTheme
        type="submit"
        padding="0.5rem 3rem"
        background="#0C0C0C"
        borderRad="5px"
        primary
        onClick={handleUpdateProfile}
      >
        Save
      </ButtonTheme>
    </form>
  );
};

export default ProfileForm;
