import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../common/Header';
import styled from '@emotion/styled';

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 90px auto 10px;
  padding: 0 20px;
`;

const MainLayout: React.FC = () => {
  return (
    <>
      <Header />
      <MainContainer>
        <Outlet />
      </MainContainer>
    </>
  );
};

export default MainLayout;