import React from 'react';
import styled from '@emotion/styled';

const TableRow = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 1fr;
  gap: 1rem;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 0rem 1rem;
  font-weight: 400;
  &:not(:last-child) { margin-bottom: 1rem; }
  .green { color: #4bb543; }
  .red { color: #fc100d; }
`;

interface PaymentRowProps {
    date: string;
    description: string;
    amount: string;
    status: string;
}

const PaymentRow: React.FC<PaymentRowProps> = ({ date, description, amount, status }) => (
    <TableRow>
        <p>{date}</p>
        <p>{description}</p>
        <p>{amount}</p>
        <p className={status === 'Successful' ? 'green' : 'red'}>{status}</p>
    </TableRow>
);

const PaymentTableContainer = styled.div` display: flex; flex-direction: column; align-items: center; `;
const TableHeader = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 1fr;
  gap: 1rem;
  padding: 1rem;
  font-weight: 400;
  p { margin: 0; }
`;

const payments = [
    { id: 1, date: '2024-04-06', description: 'Monthly - Subscription', amount: '$500', status: 'Successful' },
    { id: 2, date: '2024-04-06', description: 'Monthly - Subscription', amount: '$500', status: 'Successful' },
    { id: 3, date: '2024-04-06', description: 'Monthly - Subscription', amount: '$500', status: 'Failed' },
    { id: 4, date: '2024-04-06', description: 'Monthly - Subscription', amount: '$500', status: 'Successful' },
    { id: 5, date: '2024-04-06', description: 'Monthly - Subscription', amount: '$500', status: 'Failed' },
];

const PaymentTable: React.FC = () => (
    <PaymentTableContainer>
        <TableHeader>
            <p>Date</p>
            <p>Description</p>
            <p>Amount</p>
            <p>Status</p>
        </TableHeader>
        {payments.map(({ id, date, description, amount, status }) => (
            <PaymentRow key={id} date={date} description={description} amount={amount} status={status} />
        ))}
    </PaymentTableContainer>
);

export default PaymentTable;
