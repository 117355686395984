import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Theme } from '../../styles/theme';
import Logo from '../Logo';
import { FaArrowRight } from 'react-icons/fa'; // Import the arrow icon for the sign-in button
import Modal from './Modal'; // Import the Modal component for displaying forms
import SignInForm from './Login'; // Import the SignInForm component for user authentication
import Img from '../Img';


const HeaderContainer = styled.header`
  background-color: ${props => props.theme.colors.background};
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.5s ease-in-out; /* Smooth transition */
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.textLight};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SignButton = styled.button`
  border-radius: 100px;
  border: 1px solid #414141;
  background: #fff;
  width: 150px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 0;
  text-align: center;
  cursor: pointer;
  font-family: inherit;

  &:hover {
    background: ${props => props.theme.colors.hoverLightButton};
  }
`;

const IconWrapper = styled.span`
  margin-left: 8px;
`;

const MenuContainer = styled.div`
  border-radius: 100px;
  border: 1px solid #414141;
  background: #0C0C0C;
  padding: 1rem 2rem;
`;

const MenuLink = styled(NavLink)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 20px;

  &:hover {
    background: #323232;
    border-radius: 15px;
  }
`;

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);


  return (
    <HeaderContainer >
      <Nav>
        <NavLink to="/">
          <Img ImgName={'logos/logo_white.png'} alt="BGhost Logo" width={150} />
        </NavLink>

        <MenuContainer>
          <MenuLink to="/">Home</MenuLink>
          <MenuLink to="/about">About</MenuLink>
          <MenuLink to="/blogs">Blogs</MenuLink>
          <MenuLink to="/price">Price</MenuLink>
          <MenuLink to="/community">Community</MenuLink>
        </MenuContainer>

        <div>
          <SignButton onClick={handleOpenModal}>
            Sign In
            <IconWrapper>
              <FaArrowRight style={{'transform':'rotate(-40deg)'}} />
            </IconWrapper>
          </SignButton>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <SignInForm />
          </Modal>
        </div>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
