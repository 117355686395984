import { useState } from "react";
import styled from "@emotion/styled";
import ButtonTheme from "../common/Button";
import InputFeild from "../common/InputFeild";

const FeildContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  width: 50%;
`;

const PasswordForm: React.FC = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleUpdateProfile = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Password updated:', { password, newPassword, confirmPassword });
  };

  return (
    <form onSubmit={handleUpdateProfile}>
      <FeildContainer>
        <InputFeild label="Current Password" type="password" value={password} setValue={setPassword} />
        <InputFeild label="New Password" type="password" value={newPassword} setValue={setNewPassword} />
        <InputFeild label="Confirm New Password" type="password" value={confirmPassword} setValue={setConfirmPassword} />
      </FeildContainer>

      <ButtonTheme
        type="submit"
        padding="0.5rem 3rem"
        background="#0C0C0C"
        borderRad="5px"
        primary
      >
        Save
      </ButtonTheme>
    </form>
  );
};

export default PasswordForm;
