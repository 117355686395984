/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// Emotion CSS styles

const testimonialCard = css`
  display: flex;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
`;

const testimonialContent = css`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const imageStyle = css`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
    object-fit: cover;
`;

const nameStyle = css`
  font-size: 1.2rem;
  text-align: left;
  margin: 0;
`;

const positionStyle = css`
  font-size: 0.9rem;
  color: #777;
  margin: 1px;
`;

const testimonialText = css`
  font-size: 1rem;
  color: #333;
  text-align: left;
  font-style: italic;

`;


const testimonials = [
  {
    name: "Marcin Hoppe",
    position: "Senior Engineering Manager",
    testimonial: `There are a lot of extremely noisy tools, and they generate a lot of findings, but to get to the true positives, you have to spend a lot of time analyzing the results. So we were very happy with the low rate of Detectify's false positives.`,
    imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEOXVAe4snmDYd4z-7iIkEG3LLAlq4s_0kuw&s" // Replace with actual image
  },
  {
    name: "Michelle Tolmay",
    position: "Senior Engineering Manager",
    testimonial: `With Surface Monitoring, we found subdomains we didn’t know we had. Not only would we likely not have found these subdomains, but we also wouldn’t have known about them until someone did something really nasty on one of them and held us to ransom over it.`,
    imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1DUpFJe9wEagAMm0IVNMSBnN5wP6uif5I1g&s" // Replace with actual image
  }
  // Add more testimonials as needed
];

const TestimonialsSlider: React.FC = () => {
  return (
    <div style={{display: 'flex'}}>
        {testimonials.map((testimonial, index) => (
          <div css={testimonialCard} key={index}>
            <div>
              <div css={testimonialContent}>
                <div>
                  <img src={testimonial.imageUrl} alt={testimonial.name} css={imageStyle} />
                </div>
                <div>
                  <h3 css={nameStyle}>{testimonial.name}</h3>
                  <p css={positionStyle}>{testimonial.position}</p>
                  </div>
              </div>
              <div>
                <p css={testimonialText}>{testimonial.testimonial}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};


export default TestimonialsSlider;